<template>
  <div>
    <b-card v-if="data" class="card-app-design">
      Created by
      <span class="font-weight-bold">{{ data.consultant.name }}</span> on
      <b-badge variant="light-primary">
        {{ formatDate(data.createdAt) }}
      </b-badge>
      <b-card-title class="mt-1 mb-75">
        {{ data.task ? data.task.title : "" }}
      </b-card-title>
      <b-card-text class="font-small-2 mb-2">
        {{ data.task ? data.task.description : "" }}
      </b-card-text>

      <div class="design-group">
        <h6 class="section-label">
          Services
        </h6>
        <b-badge
          :class="['mr-1', service.dealClosed ? 'cursor-pointer' : '']"
          v-on:click="service.dealClosed ? showDealDetails(service) : null"
          v-for="service in data.services"
          :key="service.name"
          variant="light-primary"
        >
          <feather-icon
            v-if="service.dealClosed"
            icon="ThumbsUpIcon"
          ></feather-icon>
          {{ service.name }}
        </b-badge>
      </div>
      <div class="design-group">
        <h6 class="section-label">
          Commercials
        </h6>

        <div class="design-planning-wrapper">
          <div class="d-flex w-100">
            <div class="design-planning w-50">
              <p class="card-text mb-25">
                Due Date
              </p>
              <h6 class="mb-0">
                {{ data.dueDate ? formatDate(data.dueDate) : "---" }}
              </h6>
            </div>

            <div class="design-planning w-50">
              <p class="card-text mb-25">
                Budget
              </p>
              <h6 class="mb-0">₹{{ $commaNumber(data.budget) }}</h6>
            </div>
          </div>
          <div class="design-planning w-100">
            <p class="card-text mb-25">
              Cost
            </p>
            <h6 class="mb-0">₹{{ $commaNumber(data.costDetails.cost) }}</h6>
            <b-badge v-if="data.costDetails.approved" variant="success"
              >Approved</b-badge
            >
          </div>
        </div>
      </div>

      <div class="design-planning-wrapper">
        <div
          v-for="plan in data.planing"
          :key="plan.title"
          class="design-planning"
        >
          <p class="card-text mb-25">
            {{ plan.title }}
          </p>
          <h6 class="mb-0">
            {{ plan.subtitle }}
          </h6>
        </div>
      </div>

      <b-row>
        <b-col cols="6">
          <b-button
            :disabled="!openServiceExists"
            @click="closeDeal"
            block
            variant="outline-success"
          >
            Close Deal
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            :to="`/leads/${data._id}/edit`"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
          >
            Edit Lead
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <DealDetails
      :key="`deal-${serviceSelected._id}`"
      v-if="serviceSelected"
      :show="showDealDetailsModal"
      :leadID="data._id"
      :serviceID="serviceSelected._id"
      :consultantID="data.consultant._id"
      @closeModal="closeDealDetailsModal"
    />
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import DealDetails from "@/components/leads/DealDetails.vue";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    DealDetails,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          task: {
            title: "",
            description: "",
          },
          costDetails: {
            cost: 0,
            approved: false,
          },
          consultant: {
            name: "",
            _id: "",
          },
          services: [],
        };
      },
    },
  },
  computed: {
    openServiceExists() {
      let services = this.data.services || [];
      let openServices = services.filter((service) => {
        return !service.dealClosed;
      });

      return !!openServices.length;
    },
  },
  data() {
    return {
      serviceSelected: null,
      showDealDetailsModal: false,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    closeDeal() {
      this.$emit("closeDeal", true);
    },
    showDealDetails(service) {
      this.serviceSelected = service;
      this.showDealDetailsModal = true;
    },
    closeDealDetailsModal() {
      this.showDealDetailsModal = false;
    },
  },
};
</script>
