import { extend } from "vee-validate";

extend("isNumber", {
  validate(value) {
    if (!value) {
      return true;
    }
    const numberPattern = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
    if (numberPattern.test(value)) {
      return true;
    } else {
      return "The field must be a valid number";
    }
  },
});

export default {};
