<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12" md="5">
        <LeadDetailCard :data="lead" @closeDeal="toggleModal" />
      </b-col>
      <b-col cols="12" md="7" class="mb-5 mb-md-0">
        <LeadTimeline
          @timelineFetched="refreshTimeline = false"
          :refreshTimeline="refreshTimeline"
          v-if="lead && lead._id"
          :leadID="lead._id"
        />
      </b-col>
    </b-row>

    <CloseLeadDealModal
      :key="lead._id + '-' + showCloseDealModal"
      @toggleModal="toggleModal"
      @dealClosed="dealClosed"
      @dealClosureError="dealClosureError"
      v-if="lead && lead._id"
      :show="showCloseDealModal"
      :leadID="lead._id"
      :services="servicesForClosure"
    />
  </div>
</template>

<script>
import LeadDetailCard from "@/components/leads/LeadDetailCard.vue";
import LeadTimeline from "@/components/leads/LeadTimeline.vue";
import { BRow, BCol } from "bootstrap-vue";
import axios from "axios";
import CloseLeadDealModal from "@/components/leads/CloseLeadDealModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    LeadDetailCard,
    LeadTimeline,
    BRow,
    BCol,
    CloseLeadDealModal,
  },
  data() {
    return {
      lead: {
        task: {
          title: "",
          description: "",
        },
        costDetails: {
          cost: 0,
          approved: false,
        },
        consultant: {
          name: "",
        },
        services: [],
      },
      showCloseDealModal: false,
      refreshTimeline: false,
    };
  },
  computed: {
    servicesForClosure() {
      return this.lead.services.filter((service) => {
        return !service.dealClosed;
      });
    },
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    getLead() {
      let leadID = this.$route.params.leadID;
      let token = this.$getUserToken();

      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/leads/${leadID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.$set(this, "lead", response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    toggleModal() {
      this.$set(this, "showCloseDealModal", !this.showCloseDealModal);
    },
    dealClosed() {
      this.showToast(
        "Deal closed",
        "A deal has been closed successfully!",
        "success"
      );
      this.getLead();
      this.toggleModal();
      this.refreshTimeline = true;
    },
    dealClosureError() {
      this.showToast(
        "Error occured",
        "An error occured while closing the deal. Please try again!",
        "danger"
      );
    },
  },
  mounted() {
    this.getLead();
  },
};
</script>
