<template>
  <b-modal
    no-close-on-backdrop
    v-model="show"
    id="modal-center"
    hide-footer
    size="xl"
    hide-header
    centered
  >
    <b-card-text>
      <div class="header text-right">
        <b-button @click="closeModal" variant="flat-primary"
          ><feather-icon icon="XIcon"></feather-icon
        ></b-button>
      </div>
      <div class="pl-2 pr-2 pb-2 pt-0 edit-form">
        <h3 class="app-title lg">
          {{ action === "add-new" ? "Close the Deal" : "Edit Lead" }}
        </h3>
        <validation-observer ref="dealClosingForm" #default="{ invalid }">
          <div class="form-fields mt-3">
            <b-row
              v-for="(service, index) in closingServices"
              :key="service._id"
            >
              <b-col cols="12" md="3" class="d-flex align-items-center">
                <b-form-checkbox
                  class="mb-0"
                  v-model="service.isClosing"
                  switch
                  inline
                >
                </b-form-checkbox>
                <h4 class="m-0">
                  <b-badge size="lg" variant="light-primary">
                    {{ service.name }}
                  </b-badge>
                </h4>
              </b-col>

              <b-col cols="12" md="9">
                <b-row>
                  <b-col>
                    <label>Project Term</label>
                    <b-form-group>
                      <validation-provider
                        :vid="'term-' + index"
                        #default="{ errors }"
                        name="term"
                        :rules="
                          getValidationRules(service.isClosing, 'required')
                        "
                      >
                        <b-form-select
                          v-model="closingServices[index].term"
                          :options="[
                            { value: null, text: 'Select term' },
                            { value: 'one-time', text: 'One Time' },
                            { value: 'recurring', text: 'Recurring' },
                          ]"
                        ></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <label>Project Cost</label>
                      <validation-provider
                        :vid="'project-cost-' + index"
                        #default="{ errors }"
                        name="Project Cost"
                        :rules="
                          getValidationRules(
                            service.isClosing,
                            'required|isNumber'
                          )
                        "
                      >
                        <b-input-group>
                          <b-form-input
                            :disabled="!closingServices[index].term"
                            v-model="closingServices[index].projectCost"
                            id="client-name"
                            placeholder="Project Cost"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <label>Commission</label>
                      <validation-provider
                        :vid="'commission-' + index"
                        #default="{ errors }"
                        name="commission"
                        :rules="
                          getValidationRules(
                            service.isClosing,
                            'required|isNumber'
                          )
                        "
                      >
                        <b-input-group prepend="%">
                          <b-form-input
                            :disabled="!closingServices[index].term"
                            v-model="closingServices[index].commission"
                            id="client-name"
                            placeholder="Enter commission"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="closingServices[index].term === 'one-time'">
                    <b-form-group>
                      <label>Due On</label>
                      <validation-provider
                        :vid="'due-date-' + index"
                        #default="{ errors }"
                        name="due date"
                        :rules="
                          getValidationRules(service.isClosing, 'required')
                        "
                      >
                        <b-form-datepicker
                          :min-view="1"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                          }"
                          locale="en-US"
                          placeholder="Select date"
                          v-model="closingServices[index].dueDate"
                        >
                        </b-form-datepicker>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="closingServices[index].term === 'recurring'">
                    <label>Tenure</label>
                    <b-form-group>
                      <validation-provider
                        :vid="'tenure-' + index"
                        #default="{ errors }"
                        name="tenure"
                        :rules="
                          getValidationRules(service.isClosing, 'required')
                        "
                      >
                        <b-form-select
                          v-model="closingServices[index].tenure"
                          :options="tenures"
                        ></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="closingServices[index].term === 'recurring'">
                    <b-form-group>
                      <label>Starts From</label>
                      <validation-provider
                        :vid="'start-' + index"
                        #default="{ errors }"
                        name="start"
                        :rules="
                          getValidationRules(service.isClosing, 'required')
                        "
                      >
                        <b-form-datepicker
                          :disabled="!closingServices[index].tenure"
                          :min-view="1"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                          }"
                          locale="en-US"
                          placeholder="Select date"
                          v-model="closingServices[index].startsFrom"
                        >
                        </b-form-datepicker>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <div class="text-right">
              <b-button
                @click="closeTheLead"
                :disabled="isBusy"
                class="py-1 mt-2 ml-auto"
                variant="primary"
              >
                <b-spinner v-if="isBusy" small class="mr-1" />
                {{
                  action === "add-new" ? "Close the lead" : "Save Changes"
                }}</b-button
              >
            </div>
          </div>
        </validation-observer>
      </div>
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCardText,
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BRow,
  BCol,
  BFormSelect,
  BInputGroupPrepend,
  BFormDatepicker,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import "@/plugins/validationRules";
import axios from "axios";

export default {
  props: {
    leadID: {
      type: String,
      required: true,
    },
    services: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    action: {
      type: String,
      default: "add-new",
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCardText,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BInputGroupPrepend,
    BFormDatepicker,
    BBadge,
    BFormCheckbox,
  },
  data() {
    return {
      isBusy: false,
      required,
      email,
      closingServices: this.services.map((service) => ({
        ...service,
        term: service.term || null,
        projectCost: service.projectCost || null,
        commission: service.commission || null,
        tenure: service.tenure || null,
        startsFrom: service.startsFrom || null,
        dueDate: service.dueDate || null,
        isClosing: true,
      })),
      tenures: [
        { value: null, text: "Select tenure" },
        { value: 1, text: "1 Month" },
        { value: 2, text: "2 Months" },
        { value: 3, text: "3 Months" },
        { value: 4, text: "4 Months" },
        { value: 5, text: "5 Months" },
        { value: 6, text: "6 Months" },
        { value: 7, text: "7 Months" },
        { value: 8, text: "8 Months" },
        { value: 9, text: "9 Months" },
        { value: 10, text: "10 Months" },
        { value: 11, text: "11Months" },
        { value: 12, text: "12Months" },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("toggleModal");
    },
    closeTheLead() {
      this.$refs.dealClosingForm.validate().then((success) => {
        if (success) {
          this.processDealClosure();
        }
      });
    },
    getValidationRules(isClosing, rules) {
      return isClosing ? rules : "";
    },
    processDealClosure() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/close-deal`,
          { services: this.closingServices },
          {
            headers: {
              Authorization: `Bearer ${this.$getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.$emit("dealClosed", true);
        })
        .catch((error) => {
          console.log(error.response);
          this.$emit("dealClosureError", true);
        });
    },
  },
};
</script>
