<template>
  <div>
    <b-modal
      no-close-on-backdrop
      v-model="show"
      size="lg"
      id="modal-center"
      hide-footer
      hide-header
      centered
    >
      <b-card-text>
        <div class="header text-right">
          <b-button @click="closeModal" variant="flat-primary"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <div class="pl-2 pr-2 pb-2 pt-0 edit-form">
          <h3 class="app-title lg">
            {{ paymentPlan.service.name }}
          </h3>
          <div class="payment-plan-details">
            <validation-observer ref="dealDetailsForm" #default="{ invalid }">
              <b-row class="mt-4">
                <b-col cols="6">
                  <b-form-group>
                    <label>Project Term</label>
                    <validation-provider
                      #default="{ errors }"
                      name="term"
                      rules="required"
                    >
                      <b-form-select
                        disabled
                        v-model="paymentPlan.term"
                        :options="[
                          { value: null, text: 'Select term' },
                          { value: 'one-time', text: 'One Time' },
                          { value: 'recurring', text: 'Recurring' },
                        ]"
                      ></b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <label
                      >Project Cost
                      <b-badge
                        variant="light-primary"
                        v-if="paymentPlan.term === 'recurring'"
                        >Recurring</b-badge
                      >
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="Project Cost"
                      rules="required|isNumber"
                    >
                      <b-input-group>
                        <b-form-input
                          disabled
                          v-model="paymentPlan.projectCost"
                          placeholder="Project Cost"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="align-items-center">
                <b-col cols="6">
                  <b-form-group>
                    <label
                      >Commission
                      <b-badge variant="light-primary"
                        >₹{{ $commaNumber(paymentPlan.amount) }}</b-badge
                      ></label
                    >
                    <validation-provider
                      #default="{ errors }"
                      name="commission"
                      rules="required|isNumber"
                    >
                      <b-input-group prepend="%">
                        <b-form-input
                          disabled
                          v-model="paymentPlan.commission"
                          id="client-name"
                          placeholder="Enter commission"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" v-if="paymentPlan.term === 'one-time'">
                  <b-form-group>
                    <label>Due On</label>
                    <validation-provider
                      #default="{ errors }"
                      name="due date"
                      rules="required"
                    >
                      <b-form-datepicker
                        :min-view="1"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                        }"
                        locale="en-US"
                        placeholder="Select date"
                        v-model="paymentPlan.dueDate"
                      >
                      </b-form-datepicker>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" v-if="paymentPlan.term === 'recurring'">
                  <b-form-group>
                    <label>Tenure</label>
                    <validation-provider
                      #default="{ errors }"
                      name="tenure"
                      rules="required"
                    >
                      <b-form-select
                        v-model="paymentPlan.tenure"
                        :options="tenures"
                      ></b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" v-if="paymentPlan.term === 'recurring'">
                  <b-form-group>
                    <label>Starts From</label>
                    <validation-provider
                      #default="{ errors }"
                      name="start"
                      rules="required"
                    >
                      <b-form-datepicker
                        disabled
                        :min-view="1"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                        }"
                        locale="en-US"
                        placeholder="Select date"
                        v-model="paymentPlan.startDate"
                      >
                      </b-form-datepicker>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  v-if="paymentPlan.term === 'recurring'"
                  class="text-right"
                >
                  <b-button
                    :disabled="!hasChanges"
                    @click="updateTenure"
                    block
                    variant="primary"
                    class="mt-1"
                    >Update Tenure</b-button
                  >
                </b-col>

                <b-col
                  cols="6"
                  v-if="paymentPlan.term === 'one-time'"
                  class="offset-6 text-right"
                >
                  <b-button
                    :disabled="!hasDueDateChanges"
                    @click="updateDueDate"
                    block
                    variant="primary"
                    class="mt-1"
                    >Update Due Date</b-button
                  >
                </b-col>
              </b-row>
            </validation-observer>
          </div>

          <div class="payments-table mt-2">
            <h5>Payment Details</h5>
            <b-table
              :items="payments"
              :fields="fields"
              striped
              responsive
              hover
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #cell(show_details)="row">
                <b-form-checkbox
                  :disabled="row.item.status !== 'paid'"
                  v-model="row.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">{{
                    row.detailsShowing ? "Hide" : "Show"
                  }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col md="4" class="mb-1">
                      <strong>Remarks : </strong>{{ row.item.remarks }}
                    </b-col>
                  </b-row>

                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Hide
                  </b-button>
                </b-card>
              </template>

              <template #cell(id)="data">
                {{ perPage * currentPage - perPage + (data.index + 1) }}
              </template>

              <template #cell(amount)="data">
                ₹{{ $commaNumber(data.value) }}
              </template>

              <template #cell(dueDate)="data">
                {{ $formatDate(data.value, "DD-MM-YYYY") }}
              </template>

              <template #cell(status)="data">
                <b-badge :variant="status[data.value]">
                  {{ data.value }}
                </b-badge>
              </template>

              <template #cell(action)="data">
                <div class="d-flex">
                  <!-- <b-button
                    @click="deletePayment(data.item._id, data.item.status)"
                    v-if="data.item.status !== 'paid'"
                    v-b-tooltip.hover.top="'Delete Payment'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="TrashIcon"></feather-icon>
                  </b-button> -->
                  <b-button
                    @click="holdPayment(data.item._id, data.item.status)"
                    v-b-tooltip.hover.top="'Hold Payment'"
                    v-if="
                      data.item.status !== 'paid' &&
                        data.item.status !== 'on-hold'
                    "
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="PauseIcon"></feather-icon>
                  </b-button>
                  <b-button
                    @click="releasePaymentHold(data.item._id, data.item.status)"
                    v-b-tooltip.hover.top="'Withdraw Hold'"
                    v-if="data.item.status === 'on-hold'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="PlayIcon"></feather-icon>
                  </b-button>
                  <b-button
                    @click="initMarkPaid(data.item)"
                    v-b-tooltip.hover.top="'Mark Paid'"
                    v-if="
                      data.item.status !== 'paid' &&
                        data.item.status !== 'on-hold'
                    "
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="DollarSignIcon"></feather-icon>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.top="'View Transaction Details'"
                    v-if="data.item.status === 'paid'"
                    @click="data.toggleDetails"
                    variant="flat-success"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="DollarSignIcon"></feather-icon>
                  </b-button>
                </div>
              </template>
            </b-table>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                size="sm"
                class="my-0"
              />
            </div>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
      v-model="showRemarksModal"
      id="modal-prevent-closing"
      ref="my-modal"
      title="Add Remarks"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="cancelMarkPaid"
      @hidden="cancelMarkPaid"
      @ok="markPaid"
    >
      <form ref="remarkForm" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="remarkState"
          label="Remarks"
          label-for="remarks-input"
          invalid-feedback="Remark is required"
        >
          <b-form-input
            id="remarks-input"
            v-model="remark"
            :state="remarkState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BButton,
  BCardText,
  BFormGroup,
  BFormSelect,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BFormDatepicker,
  BBadge,
  BTable,
  BFormCheckbox,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import "@/plugins/validationRules";

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    leadID: {
      type: String,
      required: true,
    },
    serviceID: {
      type: String,
      required: true,
    },
    consultantID: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      default: "view",
    },
  },
  components: {
    BModal,
    BCard,
    BButton,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BFormDatepicker,
    BBadge,
    BTable,
    BFormCheckbox,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      paymentPlan: {
        service: {
          name: "",
        },
        tenure: null,
        dueDate: null,
      },
      payments: [],
      required,
      tenures: [
        { value: null, text: "Select tenure" },
        { value: 1, text: "1 Month" },
        { value: 2, text: "2 Months" },
        { value: 3, text: "3 Months" },
        { value: 4, text: "4 Months" },
        { value: 5, text: "5 Months" },
        { value: 6, text: "6 Months" },
        { value: 7, text: "7 Months" },
        { value: 8, text: "8 Months" },
        { value: 9, text: "9 Months" },
        { value: 10, text: "10 Months" },
        { value: 11, text: "11Months" },
        { value: 12, text: "12Months" },
      ],
      fields: ["id", "amount", "dueDate", "status", "action"],
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
      status: {
        pending: "warning",
        failed: "danger",
        paid: "success",
        "on-hold": "danger",
      },
      originalTenure: null,
      originalDueDate: null,
      remark: null,
      remarkState: null,
      paymentClosure: {},
      showRemarksModal: false,
    };
  },
  computed: {
    hasChanges() {
      return this.paymentPlan.tenure !== this.originalTenure;
    },
    hasDueDateChanges() {
      return this.paymentPlan.dueDate !== this.originalDueDate;
    },
    checkFormValidity() {
      const valid = this.$refs.remarkForm.checkValidity();
      this.remarkState = valid;
      return valid;
    },
  },
  methods: {
    getDealDetails() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/deal-details/${this.serviceID}/${this.consultantID}`,
          {
            headers: {
              Authorization: `Bearer ${this.$getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const { paymentPlan, payments } = response.data;
          this.paymentPlan = paymentPlan;
          this.payments = payments;
          this.totalRows = payments.length;
          this.originalTenure = paymentPlan.tenure;
          this.originalDueDate = paymentPlan.dueDate;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    updateTenure() {
      if (!this.hasChanges) {
        return;
      }

      this.$refs.dealDetailsForm.validate().then((success) => {
        if (success) {
          axios
            .put(
              `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/${this.paymentPlan._id}/update-tenure`,
              {
                tenure: this.paymentPlan.tenure,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.$getUserToken()}`,
                },
              }
            )
            .then(() => {
              this.getDealDetails();
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      });
    },
    updateDueDate() {
      this.$refs.dealDetailsForm.validate().then((success) => {
        if (success) {
          axios
            .put(
              `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/${this.paymentPlan._id}/update-due-date`,
              {
                dueDate: this.paymentPlan.dueDate,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.$getUserToken()}`,
                },
              }
            )
            .then(() => {
              this.getDealDetails();
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      });
    },
    // deletePayment(paymentID, paymentStatus) {
    //   if (paymentStatus === "paid") {
    //     return alert("Cannot delete paid payments!");
    //   }
    //   if (confirm("Are you sure you want to delete the payment?")) {
    //     axios
    //       .delete(
    //         `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/${this.paymentPlan._id}/${paymentID}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${this.$getUserToken()}`,
    //           },
    //         }
    //       )
    //       .then(() => {
    //         this.getDealDetails();
    //       })
    //       .catch((error) => {
    //         console.log(error.response);
    //       });
    //   }
    // },
    holdPayment(paymentID, paymentStatus) {
      if (paymentStatus === "on-hold") {
        return alert("Cannot process request!");
      }
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to hold the payment.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Hold",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === false) {
            return;
          }

          axios
            .put(
              `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/${this.paymentPlan._id}/${paymentID}/hold`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${this.$getUserToken()}`,
                },
              }
            )
            .then(() => {
              this.getDealDetails();
            })
            .catch((error) => {
              console.log(error.response);
            });
        });
    },
    releasePaymentHold(paymentID, paymentStatus) {
      if (paymentStatus === "pending") {
        return alert("Cannot process request!");
      }
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to release the hold on this payment.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Release",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === false) {
            return;
          }

          axios
            .put(
              `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/${this.paymentPlan._id}/${paymentID}/release-hold`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${this.$getUserToken()}`,
                },
              }
            )
            .then(() => {
              this.getDealDetails();
            })
            .catch((error) => {
              console.log(error.response);
            });
        });
    },
    markPaid(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.checkFormValidity) {
        return;
      }
      axios
        .put(
          `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/${this.paymentPlan._id}/${this.paymentClosure._id}/mark-paid`,
          {
            remarks: this.remark,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$getUserToken()}`,
            },
          }
        )
        .then(() => {
          this.showRemarksModal = false;
          this.paymentClosure = null;
          this.getDealDetails();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    initMarkPaid(payment) {
      this.paymentClosure = payment;
      this.showRemarksModal = true;
    },
    cancelMarkPaid() {
      this.showRemarksModal = false;
    },
  },
  mounted() {
    this.getDealDetails();
  },
};
</script>
