<template>
  <b-card no-body class="timeline-card">
    <b-card-header>
      <b-card-title class="ml-25"> Lead Timeline </b-card-title>
    </b-card-header>
    <b-card-body class="timeline-card-body" ref="timelineCardBody">
      <app-timeline>
        <app-timeline-item
          :icon="icons[timeline.type]"
          :variant="timeline.variant"
          v-for="(timeline, index) in timelines"
          :key="index"
        >
          <div
            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
          >
            <h6>{{ timeline.title }}</h6>
            <small class="timeline-item-time text-nowrap text-muted ml-1">{{
              $formatDate(timeline.createdAt, "DD-MM-YYYY hh:mm a")
            }}</small>
          </div>
          <p>{{ timeline.description }}</p>
          <b-media no-body>
            <b-media-aside class="mr-50">
              <b-avatar
                :variant="timeline.variant"
                :text="getInitials(timeline.user.name)"
                size="22"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <p class="mb-0 font-small-2">
                {{ timeline.user.name }}
              </p>
            </b-media-body>
          </b-media>
        </app-timeline-item>
      </app-timeline>
      <b-card-footer class="message-field">
        <div class="d-flex justify-content-between align-items-center">
          <div class="w-100 mr-1">
            <b-form-textarea
              rows="1"
              v-model="comment"
              placeholder="Add your comment / update here"
              class="mr-1"
            ></b-form-textarea>
          </div>
          <div>
            <b-button
              @click="addComment"
              :disabled="!comment"
              variant="warning"
              class="btn-icon"
            >
              <feather-icon icon="SendIcon" />
            </b-button>
          </div>
        </div>
      </b-card-footer>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import axios from "axios";
/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BCardFooter,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    leadID: {
      type: String,
      required: true,
    },
    refreshTimeline: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      variants: ["primary, warning, info, danger"],
      timelines: [],
      comment: "",
      icons: {
        comment: "MessageCircleIcon",
        delete: "Trash2Icon",
        add: "PlusIcon",
        success: "CheckIcon",
        warning: "AlertTriangleIcon",
        remove: "Trash2Icon",
        increase: "ArrowUpIcon",
        decrease: "ArrowDownIcon",
        money: "DollarSignIcon",
        info: "InfoIcon",
        "deal-closure": "AwardIcon",
      },
    };
  },
  watch: {
    refreshTimeline(newVal) {
      if (newVal === true) {
        this.getTimeline();
      }
    },
  },
  methods: {
    scrollToBottom() {
      const element = this.$refs.timelineCardBody;
      if (element) {
        const scrollHeight = element.scrollHeight;
        const start = element.scrollTop;
        const distance = scrollHeight - start;
        const duration = 500;
        let startTime = null;

        function scroll(timestamp) {
          if (!startTime) startTime = timestamp;
          const progress = timestamp - startTime;
          const ease = Math.min(progress / duration, 1);
          element.scrollTop = start + distance * ease;
          if (progress < duration) {
            window.requestAnimationFrame(scroll);
          } else {
            element.scrollTop = scrollHeight;
          }
        }

        window.requestAnimationFrame(scroll);
      }
    },
    getInitials(sentence) {
      if (typeof sentence !== "string") {
        throw new Error("Input must be a string");
      }

      const words = sentence.split(/\s+/);
      const initials = words
        .map((word) => word.charAt(0).toUpperCase())
        .join("");

      return initials;
    },
    getTimeline() {
      if (!this.leadID) {
        return;
      }

      let token = this.$getUserToken();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/timeline`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.timelines = response.data;
          this.scrollToBottom();
          this.$emit("timelineFetched", true);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    addComment() {
      let comment = this.comment;
      if (!comment) {
        return;
      }

      const token = this.$getUserToken();
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/leads/${this.leadID}/timeline`,
          {
            title: "Added a new comment",
            description: comment,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.timelines.push(response.data);
          this.scrollToBottom();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.getTimeline();
  },
};
</script>

<style scoped>
.timeline-card {
  height: calc(100vh - 190px);
  position: relative;
}

.timeline-card-body {
  overflow: scroll;
  padding-bottom: 130px;
}

.message-field {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  left: 0;
  z-index: 999;
}
</style>
